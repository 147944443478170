
import Vue from "vue";
import {
  underWritingQuestionMapActions,
  underWritingQuestionMapMutations,
  underWritingQuestionMapState
} from "@/store/modules/underWritingQuestion";
import * as types from "@/store/mutation-types";
import { underWritingQuestionDefault } from "@/helpers/underWritingQuestionsDefault";
import { FormBlock } from "@/components/FormBuilder/types";
import { underWritingQuestionsForm } from "@/forms/admin/underWritingQuestion";
import FormBuilder from "@/components/FormBuilder/FormBuilder.vue";
import { companyLineOfBusiness } from "../../../helpers/selectOptions";
import { removeEmptyFields } from "../../../helpers/removeEmptyFields";
import { camelCase, cloneDeep } from "lodash";
interface ICreateUnderWritingQuestion {
  errorMessage: string;
  successMessage: string;
  validationData: Record<string, any>;
  loadingText: string;
}
export default Vue.extend({
  components: { FormBuilder },
  name: "CreateUnderWritingQuestion",
  data(): ICreateUnderWritingQuestion {
    return {
      errorMessage: "",
      successMessage: "",
      validationData: {},
      loadingText: "Submitting new underWritingQuestion data. Please wait..."
    };
  },
  created() {
    this.SET_EDIT(underWritingQuestionDefault({}));
  },
  methods: {
    ...underWritingQuestionMapActions(["createUnderWritingQuestion"]),
    ...underWritingQuestionMapMutations(["SET_EDIT"]),
    ...underWritingQuestionMapMutations({ editField: types.SET_EDIT_FIELD }),
    formFieldChangedHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "create":
          this.submit();
          break;
        case "cancel":
          this.cancelCallback();
          break;
      }
    },
    async cancelCallback(): Promise<any> {
      this.$router.push("/admin/underwriting-questions").catch(() => {});
    },
    randomUnderWritingKeyGenerator(text: string) {
      return (
        `${Math.random()
          .toString(36)
          .substring(2, 15)}` +
        "_" +
        camelCase(text)
      );
    },
    async submit(): Promise<void> {
      try {
        if (this.formIsValid) {
          const fieldData = cloneDeep(this.editing);

          //generate random key + camelCase of fieldData.question stripped of special characters
          fieldData.key = this.randomUnderWritingKeyGenerator(
            fieldData.question
          );

          await this.createUnderWritingQuestion(
            removeEmptyFields(fieldData as any)
          );
          this.successMessage = "Underwriting Question created successfully";
          this.$router.replace("/admin/underwriting-questions").catch(() => {});
        } else {
          this.errorMessage = "Please Fill All Required Fields.";
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      }
    }
  },
  computed: {
    ...underWritingQuestionMapState(["editing", "makingApiRequest"]),
    formIsValid(): boolean {
      return !!this.validationData.formIsValid;
    },
    formFields(): FormBlock[] {
      return underWritingQuestionsForm;
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: any) => ({
        label: company.companyName,
        value: company.companyNumber
      }));
    },
    appliesToOptions(): { label: string; value: string }[] {
      return companyLineOfBusiness.map((option: any) => ({
        label: option.label,
        value: option.value
      }));
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  }
});
