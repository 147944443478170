var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"showSearch":false,"topActionButtonPrimary":{
    text: 'Create Underwriting Question',
    key: 'create',
    loading: _vm.makingApiRequest,
    disabled: !_vm.formIsValid
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  }},on:{"toolbarSelectItem":_vm.onToolbarItemClick}},[_c('form-builder',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],ref:"formElement",attrs:{"schemas":_vm.formFields,"formValues":_vm.editing,"dynamicOptions":{
      companies: _vm.companyOptions,
      policyType: _vm.appliesToOptions
    },"element-loading-text":_vm.loadingText},on:{"validationChanged":function($event){_vm.validationData = $event},"formFieldChanged":_vm.formFieldChangedHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }